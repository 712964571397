import React, { useContext } from "react";
import { Stack, Button, Box, Paper, Typography, Link, Chip, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthContext } from "../../../../components/FirebaseAuth";

import { FirebaseAuth } from '../../../../components/FirebaseAuth/firebase';
import { useParams, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    avatar: {
    },
}));

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ProductBuy = (props) => {
    const { onCancel, productId, trialTillDate, subscriptionTillDate, installationStatus } = props;
    const theme = createTheme({
        palette: {
            primary: {
              main: '#0052cc',
            },
            secondary: {
              main: '#edf2ff',
            },
          },
    })
    const classes = useStyles(theme);
    const { userData } = useContext(AuthContext);
    const { id : accountId } = userData.currentAccount;
    const query = useQuery();
    const debugMode = query.get('debugMode');

    const url = `/account/${accountId}/products/buy/${productId}/billing/plan`;
    return (
        <ThemeProvider theme={theme}>
        <Paper>
            <Box component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '35ch' },
                    p:2,
                    border: '2px solid grey'
                }}
                noValidate
                autoComplete="off"
                margin="normal"
            >
                {debugMode ? <Link href={ url }>GO TO ProductBuy Form</Link> : null}
                { installationStatus === 'no_subscription' ? <Typography>Please install component before buy it</Typography> : <></>}
                { subscriptionTillDate ? (
                            <Typography>
                                Subscribtion valid till:  <Chip label={subscriptionTillDate.toLocaleDateString()} color="success" />
                            </Typography>
                        ): 
                        (trialTillDate) ? 
                            (
                                <Typography>
                                    Trial valid till:  <Chip label={trialTillDate.toLocaleDateString()} color="warning" />
                                </Typography>
                            ) : null }
                            <Divider></Divider>
                <Stack direction="row" 
                        spacing={1}
                         mt={2} 
                        justifyContent="space-between"
                         alignItems="center">
                    { (installationStatus !== 'no_subscription' ? <Button variant="contained" color="success" href={url}>{ installationStatus === 'valid_subscription' ? 'Change Plan' : 'Subscribe'}</Button> : <></> ) }
                    <Button variant="contained" color="error" onClick={ () => onCancel() }>Close</Button>
                </Stack>
            </Box>
        </Paper>
        </ThemeProvider>
    )
}

export default ProductBuy;