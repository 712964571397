import React, {useState, useContext, useEffect, useRef} from "react";
import { BreadcrumbContext } from '../../../components/Breadcrumb';
import { FirebaseAuth } from "../../../components/FirebaseAuth/firebase";
import { useHistory, Redirect } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { Card, Button, CardActions, Grid, CardHeader } from "@mui/material";

const Home = () => {
    const title = 'My Accounts';
    const history = useHistory();   

    const { setBreadcrumb } = useContext(BreadcrumbContext);

    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const mountedRef = useRef(true);

    const getAccounts = () => {
        setLoading(true);
        let records = [];
        const accountsRef = FirebaseAuth.firestore().collection('accounts');
        let query = accountsRef.where('access', 'array-contains', FirebaseAuth.auth().currentUser.uid);
        query.get().then(accountSnapshots => {
            if (!mountedRef.current) return null
            accountSnapshots.forEach(account => {
                records.push({
                    'id': account.id,
                    'name': account.data().name,
                    'subscriptionStatus': account.data().subscriptionStatus
                });
            });
            setAccounts(records);
            setLoading(false);
        });
    }

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
        getAccounts();
    },[setBreadcrumb]);

    useEffect(() => {
        return () => { 
            mountedRef.current = false
        }
    },[]);

    const account = accounts.length > 0 ? accounts[0] : {} ;
    const redirectUrl = `/account/${account.id}`;

    return (
        <>
            {accounts.length > 0 ? (
                <>
                    <Redirect to={ redirectUrl }></Redirect>
                   
                </>
            ) : (
                <>
                    {(loading) ? (
                        <Loader text="loading accounts..."></Loader>
                    ):(
                        <Redirect to="/new-account"></Redirect>
                    )}
                </>
            )}
        </>

    )
}

export default Home;