import React, { useContext, useRef, useEffect, useState, useCallback } from "react";
import DataList from '../../../../components/DataList';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { useHistory } from "react-router-dom";
import { Stack, Button, Alert, Typography, Link } from '@mui/material';

import { FirebaseAuth } from '../../../../components/FirebaseAuth/firebase';
import ProductInstall from './ProductInstall';
import ProductBuy from './ProductBuy';
import ProductTryBeta from './ProductTryBeta';

const installProduct = CloudFunctions.httpsCallable('installProduct');
const installProductCallback = (accountId, productId, accessTocken) => installProduct({
    accountId,
    productId,
    accessTocken
})


const linkProductToApp = CloudFunctions.httpsCallable('linkProductToApp');
const linkProductToAppCallback = (accountId, productId, adaloAppId) => linkProductToApp({
    accountId,
    productId,
    adaloAppId
})

const textCalculation = (installationData) => {
    console.log('installationData', !installationData.accessTocken, !installationData.adaloAppId );
    return !installationData.accessTocken ? "Install to Adalo" : !installationData.adaloAppId ? "Link to Adalo App" : "Edit";
}  

const colorCalculation = (installationData) => {
    return !installationData.accessTocken ? "secondary" : !installationData.adaloAppId ? "secondary" : "primary";
} 

const variantCalculation = (installationData) => {
    return !installationData.accessTocken ? "contained" : !installationData.adaloAppId ? "contained" : "outlined";
} 

const ActionButtonsReadyToInstall = ( id, installation, price) => {
    const [ productInstallVisible, setProductInstallVisible ] = useState(false);
    const [ buyFormVisible, setBuyFormVisible ] = useState(false);
    const [ successInstallVisible, setSuccessInstallVisible] = useState(false)
    // const history = useHistory();
    const { userData } = useContext(AuthContext);
    const accountId = userData.currentAccount.id;

    const showForms = (isProductInstallVisible, isBuyFormVisible) => { 
        setProductInstallVisible(isProductInstallVisible);
        setBuyFormVisible(isBuyFormVisible);
    }

    const text = textCalculation(installation);
    const btnColor = colorCalculation(installation);
    const btnVariant = variantCalculation(installation);

    const installationStatus = getSubscriptionStatus(installation);
    const subscriptionTillDate = stripeDate(installation.subscriptionCurrentPeriodEnd);
    const trialTillDate = installation.trialTillDate ? new Date(installation.trialTillDate) : null;
    console.log('status:', installationStatus, installation)
    return (
        <>
            <Stack direction="row" justifyContent="flex-end" spacing={1} mt={2}>
                <Button variant={btnVariant} color={ btnColor } onClick={ () => showForms(true, false) }>{ text }</Button>
                { installationStatus === 'valid_subscription' ? 
                (<Button variant="outlined" color="success" onClick={() => showForms(false, true)}>Subscription</Button>):
                (<Button variant="contained" color="success" onClick={() => showForms(false, true)}>${ price }</Button>)
                }
            </Stack>
            <Stack direction="row"  justifyContent="flex-end" spacing={1} mt={2}>
                {
                productInstallVisible?
                    (<ProductInstall  
                        installation = { installation }
                        onCancel={ () => setProductInstallVisible(false) }
                        installComponentToAdalo = { (accessTocken) => installProductCallback(accountId, id, accessTocken) }
                        installComponentToAdaloApp = { (adaloAppId) => linkProductToAppCallback(accountId, id, adaloAppId) }
                    />):
                    (<></>)
                }
                {
                    buyFormVisible ? (<ProductBuy  
                        onCancel={ () => setBuyFormVisible(false) } 
                        productId = { id } 
                        installationStatus = { installationStatus }   
                        trialTillDate = { trialTillDate }
                        subscriptionTillDate = {subscriptionTillDate} />): (<></>)
                }
            </Stack>
        </>
    )
}
const stripeDate = (vilidDate) => {
    if (!!vilidDate) {
        return new Date(vilidDate * 1000)
    } else {
        return null;   
    }
}

const getSubscriptionStatus = installation => {
    if (!installation) return 'no_subscription';

    const hasSubscription = !!installation.stripeActiveSubscriptionID;

    if (hasSubscription)  {
        const subscriptionTillDate = stripeDate(installation.subscriptionCurrentPeriodEnd);
        if (!!subscriptionTillDate && subscriptionTillDate > new Date()) {
            return 'valid_subscription';
        } else {
            return 'expired_subscription';
        }
    }
    const trialTillDate = installation.trialTillDate ? new Date(installation.trialTillDate) : null;
    if (trialTillDate && trialTillDate > new Date()) {
        return 'valid_trial';
    } else if (trialTillDate) {
        return 'expired_trial'
    }

    return 'no_subscription'
};

const ActionButtonsInProgress = (applyToBetaUrl) => {
    const [ visible, setVisible ] = useState(false);
    return ( 
        <>
            <Stack direction="row" justifyContent="flex-end" spacing={1} mt={2}>
                <Button variant="contained" onClick={ () => setVisible(true) }> Apply to Beta Test</Button>
            </Stack>
            <Stack direction="row"  justifyContent="flex-end" spacing={1} mt={2}>
               { 
                    visible ? (<ProductTryBeta applyToBetaUrl = { applyToBetaUrl }  onCancel={ () => setVisible(false) }/>) : (<></>) 
                }
            </Stack>
        </>
    );
}
const ActionButtons = ({ id, installation, price, status, applyToBetaUrl }) => {
    if (status === 'in_progress') {
        return ActionButtonsInProgress(applyToBetaUrl)
    }

    return ActionButtonsReadyToInstall(id, installation, price)
}

const listFields = [
    {
        "name": "Image",
        "field": "image",
        "style": {"width": "8%"}
    },
    {
        "name": "Title",
        "field": "title",
        "style": {"width": "10%"}
    },
    {
        "name": "Description",
        "field": "description",
        "style": {"width": "40%"}
    },
    {
        "name": "Actions",
        "field": "action",
        "style": {"width": "35%", "textAlign":"right"}
    }
];


const ProductList = () => {
    const title = "Images";
    const { userData } = useContext(AuthContext);
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const history = useHistory();
    const [refreshCount, setRefreshCount] = useState(0);
    const currentPage = useRef(0);
    const records = useRef([]);

    const handleFetch = useCallback(
        (page, pageSize) => { 
            return new Promise((resolve, reject) => { 
                currentPage.current = page;
               
                const Firestore = FirebaseAuth.firestore();
               
                const proudctRef = Firestore.collection('products').orderBy('status', 'desc');
                const accountRef = Firestore.doc('accounts/' + userData.currentAccount.id);
                const installationsRef = Firestore.collection('accounts/' + userData.currentAccount.id + '/installations');
               
                const promise = Promise.all([proudctRef.get(), accountRef.get(), installationsRef.get()]);
                return promise.then(([products, account, installations]) => {
                    console.log(products, account, installations);

                    if(account.exists){
                        console.log('acc-data', account.data());
                    }
                    const installedProducts = {};
                    if (!installations.empty) {
                        installations.forEach(installation => {
                            console.log('acc-installation', installation.data().product.id); 
                            installedProducts[installation.data().product.id] = installation.data();
                        });
                    }

                    // "componentAppLink": "https://previewer.adalo.com/9f129bc5-714e-4120-8bcb-48833026b532",
                    // "componentConfigurationLink": "https://youtu.be/kRxDS92urXQ",
                    // "componentDemoLink": "https://youtube.com/shorts/MG2_g_ZXz3s",
                    let docs = [];
                    const productFields = [ "name", "title", "description", "imgUrl", "status", "price", "applyToBetaUrl", "termOfUse", "componentAppLink", "componentConfigurationLink", "componentDemoLink"  ];
                    if(!products.empty){
                        products.forEach(product => {
                            docs.push({
                                id: product.id,
                                _doc: product
                            });
                            productFields.forEach(field => {
                                docs[docs.length-1][field] = product.data()[field];
                            })
                        });
                    }
                    console.log(docs);
                    const _rows = [];
                    for (let i=0; i<docs.length; i++) { 
                        const { id, imgUrl, title, description, price } = docs[i];
                        _rows.push({id, imgUrl, activeLinces: 0, title, description, price });
                    }

                    const rows = [];
                    for (let i=0; i<docs.length; i++) {
                        const installation = installedProducts[docs[i].id] || {};
                        const subscriptionStatus = getSubscriptionStatus(installation);
                        const row = {
                            id: docs[i].id,
                            imgUrl: docs[i].imgUrl,
                            subscriptionStatus,
                            title: docs[i].title,
                            description: <Typography>{docs[i].description} {docs[i].termOfUse ?  <Link href = { docs[i].termOfUse }>Term of use</Link> :<></> } </Typography>,
                            image: <img alt={docs[i].title} src={docs[i].imgUrl} width={64} />,
                            action: <ActionButtons 
                                id = {docs[i].id} 
                                installation = { installation } 
                                price = { docs[i].price } 
                                status = { docs[i].status }
                                subscriptionStatus = { subscriptionStatus }
                                applyToBetaUrl = { docs[i].applyToBetaUrl }
                            />
                        };
                        rows.push(row);
                    }
                    resolve({
                        total: 2,
                        data: rows
                    });
                })

            });
        },
        [userData]
    );
    const mailTo = 'mailto:info@itsmi.store'; 
    return (
        <Stack spacing={3}>
            <Alert severity="warning">We're in Beta mode. In case of any issue with solution please contact us <Link href = {mailTo}>info@itsmi.store</Link> </Alert>
            <Alert severity="info">
               Install component to your Adalo application by one click
            </Alert>
            <DataList handleFetch={handleFetch} schema={listFields} />
        </Stack>
    );
}

export default ProductList;