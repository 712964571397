import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const signInUrl = '/signin'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ProductItem({ product }) {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  const price = product.price ? `from $${ product.price }` : null ;
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ borderRadius:0 }} aria-label="recipe" src = { product.imgUrl } >
            R
          </Avatar>
        }
        title={ product.title }
        subheader= { price }
        textAlign = { "left" }
      />
      <CardContent>
        <Typography variant="body2" textAlign={"justify"} color="text.secondary">
          { product.description } { " " } { product.termOfUse ?  <Link href = { product.termOfUse }>Term of use</Link> :<></> }
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Button size="small" onClick={handleExpandClick} >Install</Button>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Alert severity="info" style={{marginBottom:15}}> To Install the component to your Adalo project you need <Link href = {signInUrl}> Sign in</Link></Alert>
        </CardContent>
      </Collapse>
    </Card>
  );
}