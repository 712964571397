import React, { useContext, useRef, useEffect, useState, useCallback } from "react";
import DataList from '../../../components/DataList';
import { useHistory } from "react-router-dom";
import { Stack, Button, Alert, Link, Grid, Typography } from '@mui/material';

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { Firestore } from "../../../components/FirebaseAuth/firebase";

import ProductTryBeta from './ProductTryBeta';
import ProductItem from './ProductItem';

const applyToBetaUrl = '/signin'

const ActionButtonsInProgress = (applyToBetaUrl) => {
    const [ visible, setVisible ] = useState(false);
    return ( 
        <>
            <Stack direction="row" justifyContent="flex-end" spacing={1} mt={2}>
                <Button variant="contained" onClick={ () => setVisible(true) }>Try trial</Button>
            </Stack>
            <Stack direction="row"  justifyContent="flex-end" spacing={1} mt={2}>
               { 
                    visible ? (<ProductTryBeta applyToBetaUrl = { applyToBetaUrl }  onCancel={ () => setVisible(false) }/>) : (<></>) 
                }
            </Stack>
        </>
    );
}
const ActionButtons = ({ id }) => {
    return ActionButtonsInProgress(applyToBetaUrl)
}

const listFields = [
    {
        "name": "Image",
        "field": "image",
        "style": {"width": "8%"}
    },
    {
        "name": "Title",
        "field": "title",
        "style": {"width": "10%"}
    },
    {
        "name": "Description",
        "field": "description",
        "style": {"width": "40%"}
    },
    {
        "name": "Actions",
        "field": "action",
        "style": {"width": "35%", "textAlign":"right"}
    }
];


const ProductList = () => {
    const [ products, setProducts ] = useState([]);
    const [ loaded, setLoaded ] = useState(false);
    useEffect(() => {
        const proudctsRef = Firestore.collection('products').orderBy('status', 'desc').get();
        return Promise.all([ proudctsRef ]).then(([productList]) => {
            console.log(productList);
            const docs = [];
            const productFields = [ "name", "title", "description", "imgUrl", "status", "price", "applyToBetaUrl", "termOfUse" ];
            if(!productList.empty){
                productList.forEach(product => {
                    console.log(product.id, '_id_')
                    docs.push({
                        id: product.id,
                        _doc: product
                    });
                    productFields.forEach(field => {
                        docs[docs.length-1][field] = product.data()[field];
                    })
                });
            }
            console.log(docs.length, 'length');
            const rows = [];
            for (let i=0; i<docs.length; i++) { 
                const { id, imgUrl, title, description, price, termOfUse } = docs[i];
                rows.push({id, imgUrl, activeLinces: 0, title, description, price, termOfUse });
            }
            console.log(rows);
            setProducts(rows);
            setLoaded(true)
        });
    }, 
    []);

    return (
        <Stack>
            <Alert severity="info" style={{marginBottom:15}}>
               Install component to your Adalo application by one click. Before install you need to <Link href = {applyToBetaUrl}> Sign in</Link>
            </Alert>
            { 
            ((!loaded) ? (
                <Typography>Loading...</Typography>
            ) : (
            <Grid container spacing={3}>
                { 
                    products.map((product)=> {
                        return (<Grid item xs={12} sm={6} md={4}><ProductItem product={ product }/></Grid>);
                    }) 
                }
            </Grid>)
            )}
        </Stack>
    );
}

export default ProductList;