import React, { useState } from "react";
import { Stack, Button, Box, Paper, Typography, Alert, Link } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMarkSharp'
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl, { useFormControl } from '@mui/material/FormControl'
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import FileDownload from '@mui/icons-material/FileDownload';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles((theme) => ({
    avatar: {},
}));

const mailTo = 'mailto:info@itsmi.store'; 
const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const ProductEdit = (props) => {
    const { onCancel } = props;
    const { installComponentToAdalo } = props;
    const { installComponentToAdaloApp } = props;
    const { installation } = props;

    const installationData = installation;
    const [ accessTockenSetUp, setAccessTockenSetUp ] = useState(!!installationData.accessTocken);
    const [ adaloAppIdSetUp, setAdaloAppIdSetUp ] = useState(!!installationData.adaloAppId);
    
    const [ accessTocken, setAccessTocken ] = useState(installationData.accessTocken);
    const [ adaloAppId, setAdaloAppId ] = useState(installationData.adaloAppId);

    const [ successInstallVisible, setInstallComponentToAdalo ] = useState(false);
    const [ errorInstallVisible, setErrorComponentToAdalo ] = useState(false);

    const [errorAdaloAppId, setErrorAdaloAppId ] = useState(false);
    const [errorAccessTocken, setErrorAccessTocken ] = useState(false);

    const [successInstallAccessToken, setSuccessInstallAccessToken ] = useState(accessTockenSetUp);
    const [successInstallAdaloAppId, setSuccessInstallAdaloAppId ] = useState(adaloAppIdSetUp);
    const [installingAccessToken, setInstallingAccessToken]  = useState(false);
    const [installingAdaloAppId, setInstallingAdaloAppId]  = useState(false);
    
    
    const theme = createTheme({
        palette: {
            primary: {
              main: '#0052cc',
            },
            secondary: {
              main: '#edf2ff',
            },
          },
    });
    const classes = useStyles(theme);
    return (
        <ThemeProvider theme={theme}>
        <Paper>
            <Box 
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '50ch' },
                    p:2,
                    border: '2px solid grey'
                }}
                noValidate
                autoComplete="off"
                margin="normal">
                { successInstallVisible ? 
                (<Alert severity="success">
                    Component successfully installed
                </Alert>): null
                }
                { errorInstallVisible ? 
                (<Alert severity="error">
                    Error during installation. Please contact contact us <Link href = {mailTo}>info@itsmi.store</Link>  for support
                </Alert>): null
                }
                <FormControl error sx={{ width: '25ch' }}>
                    <Stack direction="row" 
                           spacing={1}
                           mt={2} 
                           justifyContent="flex-start"
                           alignItems="center"
                           pb={2}>
                        <Typography>Install component to Adalo</Typography>
                        <Avatar className={classes.avatar}>
                            <a href="https://developers.adalo.com/docs/workflow/managing-private-libraries/#sharing-private-libraries-with-other-developers" target="_blank" >
                                <QuestionMarkIcon fontSize="small"/>
                            </a>
                        </Avatar>
                    </Stack>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Access Tocken"
                            inputProps={{ 'aria-label': 'Access Tocken' }}
                            value={ accessTocken }
                            onChange = { (e) =>  setAccessTocken(e.target.value) }
                            disabled = { accessTockenSetUp || installingAccessToken }
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton 
                            color="primary" sx={{ p: '10px' }} 
                            aria-label="directions"
                            onClick={ () => { 
                                if (!guidPattern.test(accessTocken)) {
                                    return setErrorAccessTocken(true)
                                } else {
                                    setErrorAccessTocken(false)
                                }
                                setInstallingAccessToken(true)
                                installComponentToAdalo(accessTocken)
                                .then(result => {
                                    setInstallComponentToAdalo(true);
                                    setErrorComponentToAdalo(false);
                                    setSuccessInstallAccessToken(true);
                                    setAccessTockenSetUp(!!accessTocken);
                                    setInstallingAccessToken(false);
                                    installationData.accessTocken = accessTocken;
                                })
                                .catch(error => {
                                    console.log('Error', error);
                                    setErrorComponentToAdalo(true);
                                    setInstallComponentToAdalo(false);
                                    setSuccessInstallAccessToken(false);
                                    setInstallingAccessToken(false);
                                })
                            }}
                            disabled = { accessTockenSetUp || installingAccessToken}>
                            { successInstallAccessToken ? <CheckIcon /> : <FileDownload />}
                            { installingAccessToken && (
                                <CircularProgress
                                    size={32}
                                    sx={{
                                    position: 'absolute',
                                    top: 6,
                                    left: 6,
                                    zIndex: 1,
                                    }}
                                />
                                )}
                        </IconButton>
                    </Paper>
                    { errorAccessTocken ? <FormHelperText id="component-error-text">Access token should have format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx. <a href="https://developers.adalo.com/docs/workflow/managing-private-libraries/#sharing-private-libraries-with-other-developers" target="_blank" >Read more...</a></FormHelperText> : null }
                </FormControl>
                <FormControl sx={{ width: '25ch' }} error>
                    <Stack direction="row" 
                            spacing={1}
                            mt={2} 
                            justifyContent="flex-start"
                            alignItems="center"
                            pb={2}>
                        <Typography>Link component to Adalo App</Typography>
                        <Avatar className={classes.avatar}>
                            <a href="https://forum.adalo.com/t/i-dont-find-app-id/10788/2" target="_blank" >
                                <QuestionMarkIcon fontSize="small"/>
                            </a>
                        </Avatar>
                    </Stack>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
                        >
                        <InputBase
                            error = "true"
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Application ID"
                            inputProps={{ 'aria-label': 'Access Tocken' }}
                            value={ adaloAppId }
                            onChange = { (e) => setAdaloAppId(e.target.value) }
                            disabled = { !accessTockenSetUp || adaloAppIdSetUp }
                            helptext = { "some error" }
                            errortext={ 'extra error' }
                            aria-describedby="component-error-text"
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" 
                            onClick={
                                () => {
                                    if (!guidPattern.test(adaloAppId)) {
                                        return setErrorAdaloAppId(true)
                                    } else {
                                        setErrorAdaloAppId(false)
                                    }
                                    setAdaloAppIdSetUp(!!adaloAppId);
                                    setInstallingAdaloAppId(true)
                                    installComponentToAdaloApp(adaloAppId)
                                        .then(()=> {
                                            installationData.adaloAppId = adaloAppId;
                                            setInstallingAdaloAppId(false)
                                            setSuccessInstallAdaloAppId(true);
                                        })
                                } 
                            }
                            disabled = { !accessTockenSetUp || adaloAppIdSetUp }>
                            { successInstallAdaloAppId ? <CheckIcon /> : <FileDownload /> }
                            { installingAdaloAppId && (
                                <CircularProgress
                                    size={32}
                                    sx={{
                                    position: 'absolute',
                                    top: 6,
                                    left: 6,
                                    zIndex: 1,
                                    }}
                                />
                                )}
                        </IconButton>
                    </Paper>
                    { errorAdaloAppId ?  <FormHelperText id="component-error-text">Adalo app Id should have format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx. <a href="https://forum.adalo.com/t/i-dont-find-app-id/10788/2" target="_blank" >Read more...</a></FormHelperText> : null }
                </FormControl>
                <Stack direction="row" 
                            spacing={1}
                            mt={2} 
                            justifyContent="flex-start"
                            alignItems="center"
                            pb={2}>
                        <Typography>Where can I find installed library?  <a href="https://developers.adalo.com/docs/workflow/managing-private-libraries#adding-a-private-component-to-a-team" target="_blank" >
                                Click to read more...
                            </a></Typography>
                    </Stack>
                
                <Stack direction="row" 
                        spacing={1}
                        mt={2} 
                        justifyContent="space-between"
                        alignItems="center">
                    <Button variant="contained" 
                        onClick={ () =>  { 
                            setAdaloAppIdSetUp(false); 
                            setAccessTockenSetUp(false);
                            setSuccessInstallAccessToken(false);
                            setSuccessInstallAdaloAppId(false);
                        } 
                    }>Edit</Button>
                    <Button variant="contained" color="error" onClick={ () => onCancel() }>Close</Button>
                </Stack>
            </Box>
        </Paper>
        </ThemeProvider>
    )
}

export default ProductEdit;