import React from "react";
import { Stack, Button, Box, Paper, Typography, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    avatar: {
    },
}));

const ProductTryBeta = (props) => {
    const { onCancel, applyToBetaUrl } = props;
    const theme = createTheme({
        palette: {
            primary: {
              main: '#0052cc',
            },
            secondary: {
              main: '#edf2ff',
            },
          },
    })
    const classes = useStyles(theme);
    return (
        <ThemeProvider theme={theme}>
        <Paper>
            <Box component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '10ch' },
                    p:2,
                    border: '2px solid grey'
                }}
                noValidate
                autoComplete="off"
                margin="normal"
            >
                <Typography>You need to <Link href = {applyToBetaUrl}> Sign in</Link> before installing a trial</Typography>
                <Stack direction="row" 
                        spacing={1}
                        mt={1} 
                        justifyContent="space-between"
                         alignItems="center">
                    <Button variant="contained" color="error" onClick={ () => onCancel() }>Close</Button>
                </Stack>
            </Box>
        </Paper>
        </ThemeProvider>
    )
}

export default ProductTryBeta;